<template>
    <div class="rating-section rating-section__small">
        <div class="rating" v-if="!state" @click="toggle">
            <span></span>
            <a role="button" class="rating__result">Favorite</a>
        </div>
        <div class="rating" v-if="state" @click="toggle">
            <span class="rating__current"></span>
            <a role="button" class="rating__result">Favorited</a>
        </div>
    </div>
</template>
<script>
import ecosystemsService from '@/services/ecosystems.service';

export default {
    name: 'FavoriteToggle',
    props: {
        id: Number,
        state: Boolean
    },
    methods: {
        async toggle() {
            await ecosystemsService.setFavorite(this.id, !this.state);
            this.$emit('done', !this.state);
        }
    }
};
</script>
